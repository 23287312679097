.App {
  text-align: center;
  padding-bottom: 60px;
}

.form-container {
  margin: 3vh auto;
  max-width: 800px;
}

.App-logo {
  width: auto; /* Adjust the value as needed */
  height: auto; /* Maintain aspect ratio */
  max-width: 250px;
  max-height: 80px;
  pointer-events: none;
  margin: 20px 0px; 
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 1vmin); /* Slightly smaller font-size */
  color: #858585;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header-title {
  font-size: calc(20px + (34 - 20) * ((100vw - 320px) / (1600 - 320))) !important;
  color: #444; /* Dark gray */
  font-weight: 800 !important;    
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9); /* Light overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above everything */
}


